export const PAGES = {
  PersonList: "/",
  PersonListByFuneralHome: "/uitvaartcentrum/:alias",
  PersonDetail: "/:name",
  CondolenceFlowers: "/:name/flowers",
  ParticipationCard: "/:name/participation-card",
  ParticipationCardShop: "/:name/participation-card/shop",
  FuneralFlower: "/:name/flowers/funeral-flower",
  MourningVisitPage: "/:name/mourning-visit",
  CondolenceForm: "/:name/condolence",
  FinancialSupport: "/:name/financial-support",
  ComfortList: "/:name/comfort-list-card",
  ComfortListShop: "/:name/comfort-list-card/shop",
};
